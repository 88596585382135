<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" class="text-center pt-5">
        <h4 class="text-h4 font-weight-regular">Logs</h4>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="loading">
      <v-col>
        <div class="text-center py-15">
          <v-progress-circular indeterminate size="50" color="grey" />
          <div class="py-2">Loading</div>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" v-else>
      <v-col cols="12" lg="8" xl="6">
        <v-card>
          <v-list>
            <template v-for="(app, i) in Object.values(applications)">
              <v-divider :key="i" v-if="i" />
              <v-list-item :key="app.id" @click="activeItem = app.id">
                <v-list-item-icon>
                  <v-icon>mdi-file-document</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ app.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog fullscreen v-model="showModal" transition="dialog-bottom-transition">
      <ApplicationLogCard
        v-if="activeItem"
        :link="applications[activeItem].link"
        :title="applications[activeItem].title"
        @close="activeItem = null"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import startCase from "lodash.startcase";
import * as api from "@/constants/api";
import { serviceNames } from "@/constants/serviceNameMap";

export default {
  name: "ApplicationLogs",
  metaInfo: { title: "Application Logs" },
  components: {
    ApplicationLogCard: () => import("@/views/components/Management/ApplicationLogCard"),
  },
  data: () => ({
    loading: false,
    applications: {},
    activeItem: null,
  }),
  computed: {
    showModal: {
      get() {
        return !!this.activeItem;
      },
      set(val) {
        if (!val) this.activeItem = null;
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;

        const applications = {};

        const addApp = ([route, name]) => {
          applications[name] = {
            id: name,
            title: serviceNames[name] || startCase(name),
            link: route.replace("/**", api.ACTUATOR_ROUTES_LOGFILE),
          };
        };

        addApp(["/**", "gateway-service"]);
        const data = await this.$axios.$get(api.ACTUATOR_ROUTES);
        Object.entries(data).forEach(addApp);

        this.applications = applications;
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
